import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Col } from 'antd';
import { drop } from 'lodash';
import { EntityTabFieldProps } from '../EntityTabFieldsTypes';
import {
  CustomField_FRAGMENT,
  CustomFieldProps,
  CustomFieldValue_FRAGMENT
} from '../../../../CustomField/CustomFieldUtils';
import CustomFieldText from '../../../../CustomField/CustomFieldText';
import CustomFieldSwitch from '../../../../CustomField/CustomFieldSwitch';
import CustomFieldNumber from '../../../../CustomField/CustomFieldNumber';
import CustomFieldTag from '../../../../CustomField/CustomFieldTag';
import CustomFieldSelectMultiSupport from '../../../../CustomField/CustomFieldSelectMultiSupport';
import CustomFieldCheckMultiSupport from '../../../../CustomField/CustomFieldCheckMultiSupport';
import {
  CustomFieldTypeEnum,
  EntityTabCustomFieldDataQueryQuery,
  EntityTabCustomFieldDataQueryQueryVariables
} from '../../../../../../gql/typings';
import { useSystemCountriesState } from '../../../../../util/useSystemCountriesState';


export const customFieldTypes: Record<CustomFieldTypeEnum,
React.FC<CustomFieldProps>> = {
  TEXT: CustomFieldText,
  SELECT: CustomFieldSelectMultiSupport,
  CHECK: CustomFieldCheckMultiSupport,
  NUMBER: CustomFieldNumber,
  SWITCH: CustomFieldSwitch,
  TAG: CustomFieldTag,
};

const CustomFieldTabField: React.FC<EntityTabFieldProps> = (props) => {
  const [countries] = useSystemCountriesState();
  const {
    data,
    refetch,
  } = useQuery<EntityTabCustomFieldDataQueryQuery, EntityTabCustomFieldDataQueryQueryVariables>(DATA_QUERY, {
    variables: { fieldId: props.field.id, recordId: props.recordId, countries },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const saveState = useState<((recordId: number) => Promise<any>)[]>([]);
  const [toSave, setToSave] = saveState;


  const { recordId } = props;
  useEffect(() => {
    if (toSave.length > 0) toSave[0]?.(recordId)
      .then(refetch)
      .then(() => setToSave(prev => drop(prev, 1)));
  }, [recordId, toSave, refetch, setToSave]);

  if (!data?.info?.fieldConfig?.customField.fieldType.enum) return <></>;
  if (!data?.info?.fieldConfig.customField.isActive) {
    return <></>;
  }
  const fieldEnum = data?.info?.fieldConfig?.customField?.fieldType?.enum;
  if (!fieldEnum) return <></>;
  const ToDisplay = customFieldTypes[fieldEnum]!;
  if (!ToDisplay) return <></>;


  return (
    <>
      {
        data?.info?.fieldConfig?.customField.myAccessRules!.allowRead && <>
          <Col className="custom-field-heading" span={8}>
            {data?.info?.fieldConfig?.customField?.heading}:
          </Col>
          <Col span={16}>
            <ToDisplay
              field={data.info.fieldConfig.customField}
              values={data.info.fieldConfig.customField.customLinkValues!}
              saveState={saveState}
            />
          </Col>
        </>
      }
    </>
  );
};

const DATA_QUERY = gql`
  query EntityTabCustomFieldDataQuery($fieldId: Int!, $recordId: Int!, $countries: [CountryCode!]) {
    info: entityTabGridConfigurationField(id: $fieldId) {
      id
      fieldConfig: customFieldConfiguration {
        id
        customField {
          code
          heading
          myAccessRules {
            id
            allowMutation
            allowRead
          }
          ...CustomFieldFragment
          customLinkValues(recordId: $recordId, criteria: { fetchSize: { limit: null } }) {
            hash
            ...CustomFieldValueFragment
          }
        }
      }
    }
  }
  ${CustomField_FRAGMENT}
  ${CustomFieldValue_FRAGMENT}
`;

export default CustomFieldTabField;
